import React from 'react';
import './RefundPolicy.css';

const RefundPolicy = () => {
    return (
        <div className='staticContent'>
            <h4>Refund Policy</h4>
            <h6>What is Loreum Ipsum?</h6>
            <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, repellendus beatae nulla minima voluptates eaque dolore placeat doloribus aperiam, atque deserunt aspernatur. Temporibus, cum sint? Dolores quos perferendis officia minus.
            </p>
            <h6>What is Loreum Ipsum?</h6>
            <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, repellendus beatae nulla minima voluptates eaque dolore placeat doloribus aperiam, atque deserunt aspernatur. Temporibus, cum sint? Dolores quos perferendis officia minus.
            </p>
            <h6>What is Loreum Ipsum?</h6>
            <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, repellendus beatae nulla minima voluptates eaque dolore placeat doloribus aperiam, atque deserunt aspernatur. Temporibus, cum sint? Dolores quos perferendis officia minus.
            </p>
            <h6>What is Loreum Ipsum?</h6>
            <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, repellendus beatae nulla minima voluptates eaque dolore placeat doloribus aperiam, atque deserunt aspernatur. Temporibus, cum sint? Dolores quos perferendis officia minus.
            </p>
            <h6>What is Loreum Ipsum?</h6>
            <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, repellendus beatae nulla minima voluptates eaque dolore placeat doloribus aperiam, atque deserunt aspernatur. Temporibus, cum sint? Dolores quos perferendis officia minus.
            </p>
        </div>
    )
}

export default RefundPolicy